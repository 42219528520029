<template>
    <!--Counter Header-->
     <div class="buy-ad About bg-white">
        <div class="container is-max-desktop">
          <h1 class="column is-half is-offset-one-quarter menlo color-white f40 text-center p200 px-3">What is the Counter?</h1>
        </div>
      </div>
  
      <!--Counter text-->
      <div class="start-steps about-home2 bg-purple py-6">
        <div class="container  py-6">
          <p class="text-left f13 latoregular color-white px-3 py-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
          <p class="text-left f13 latoregular color-white px-3 py-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
      </div>

      <div class="start-steps bg-purple py-6">
        <div class="container  py-6">
          <h1 class="menlo color-white f30 text-center py-5">The Steps on the Counter</h1>
          
          <p class="text-left f13 latoregular color-white px-3 py-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
       </div>
      </div>



      <!-- STEPS section -->
      <div class=" bg-purplegradient">
        <div class="container py-6 is-fluid ">
          <div class="columns pt-3">
            <div class="column mt-4">
              <h1 class="menlo color-white f30 text-left ">1 - Onboarding</h1>
              <p class="pt-2 lato f13 color-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="column mt-6">
              <img class=" mt-6" width="185" height="91" src="https://res.cloudinary.com/nieleche/image/upload/v1675389263/Group_54_xxllzc.png" alt="Image">
              <h1 class="menlo color-white f30 text-left ">2 - Trading</h1>
              <p class="pt-2 lato f13 color-white text-left ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
          </div> 

          <div class="columns pb-5">
            <div class="column mt-6 text-left">
              <h1 class="menlo color-white f30 text-left  is-half">3 - Payment & Settlement</h1>
              <p class="pt-2 lato f13 color-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>

            <div class="column">
              <img class="is-hidden-mobile mt-0" width="185" height="91" src="https://res.cloudinary.com/nieleche/image/upload/v1676938555/Group_54_xxllzc_hl0ijc.png" alt="Image">
            </div>
          
          </div> 
        </div>
      </div>


      <div class="buy-ad bg-black py-6">
        <div class="container  py-6">
          <h1 class="menlo color-white f30 text-center py-5">Our Counter Platfrom</h1>
          <div class="column is-half is-offset-one-quarter">
            <article>
              <div class="media-left bg-white">
                <figure>
                  <img  src="https://res.cloudinary.com/nieleche/image/upload/v1676940404/y8-PTBaP90a_zssvnw.png" alt="Image">
                </figure>
              </div>
              <div class="media-content media-left bg-white">
                <div class="bg-purple color-white p16">
                  <p class="f20 color-white mb-0">
                    <strong class="color-white">WHATSAPP</strong>  
                  </p>
                    <p class="f13">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p> 
                    
                </div>
              </div>
            </article>

          <a href="https://api.whatsapp.com/send?phone=0000000000"  class="button is-ghost bg-purple textdecoration-none color-white f16 my-5">Get Started </a>

          </div>

        </div>
      </div>



  
      <!--Images-->
      <div class="bg-purple">
        <div class="container px-0 is-fluid ">
          <div class="columns mb-0">
            <div class="column p-0">
              <img class="fit" src="https://res.cloudinary.com/nieleche/image/upload/v1676935640/pexels-christina-morillo-1181583_bdbqts.jpg" alt="Image">
            </div>
            <div class="column p-0">
              <img class="fit" src="https://res.cloudinary.com/nieleche/image/upload/v1675394053/Mask_group_bajxj7.png" alt="Image">
            </div>
          </div> 
        </div>
      </div>
  
  
  </template>
  
  
  
  <script>
  
  export default {
    name: 'counterView',
    components: {
   
    }
  }
  </script>
  