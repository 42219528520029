<template>
  <div class="home">

<!-- First section on home page - get started and welcome-->   
    
    <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap bg-purple">
      <div class="px-5 column is-half py-6">
       <h2 class=" menlo color-white mt-5 f50 ">Transact Crypto<br>easily</h2>
       <p class=" mt-2 color-white latoregular f16 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
      <router-link to="/register"  class="button f16 bg-purple color-white my-5">Get Started </router-link>

      <div class="start is-flex is-justify-content-space-between mt-5 menlo f20 color-white">
          <div>
            <p>
              20M+ 
            </p>
            <div class="f13 latoregular">Registered users</div>
          </div>

          <div>
            <p> 300M+ </p>
            <div class="f13 latoregular">Transactions completed</div>
          </div>
          <div>
            <p> 0.10%  </p>
            <div class="f13 latoregular">Lowest transaction fee</div>
        </div>
      </div>
       </div> 
       <div class="bg-purple py-6">
        <img class="p-5" width="472" height="490" src="https://res.cloudinary.com/nieleche/image/upload/v1675390688/NFT-alt-mobile_2_zsqxgq.png" alt="Image">
        </div>
    </div>
    <!-- Ad section -->
    <div class="buy-ad ">
      <div class="container is-max-desktop">
        <h1 class="column is-half is-offset-one-quarter menlo color-white f40 text-center p200 px-3">Trade Fast, Trade Smart, Trade Rapid</h1>
      </div>
    </div>

    <!-- Account completion steps section -->
    <div class="start-steps bg-black">
      <div class="container is-fluid py-6">
        <h1 class="menlo color-white f30 text-center py-5">Build your Rapidbear portfolio</h1>

        <!-- Steps-->
        <div class="columns ">
          <div class="column">
            <article>
              <div class="media-left bg-white">
                <figure>
                  <img height="144" src="https://res.cloudinary.com/nieleche/image/upload/v1674530353/verify_izvhmn.png" alt="Image">
                </figure>
              </div>
              <div class="media-content media-left bg-white">
                <div class="content p16">
                  <p class="f20">
                    <small class="f13">Takes about 10 mins</small> 
                    <br>  
                    <strong>Verify your identity</strong>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div class="column">
            <article>
              <div class="media-left bg-white">
                <figure>
                  <img height="144" src="https://res.cloudinary.com/nieleche/image/upload/v1674530481/fund_qj6csw.png" alt="Image">
                </figure>
              </div>
              <div class="media-content media-left bg-white">
                <div class="content p16">
                  <p class="f20">
                    <small class="f13">Takes about 10 mins</small> 
                    <br>  
                    <strong>Fund your account</strong>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div class="column">
            <article>
              <div class="media-left bg-white">
                <figure>
                  <img height="144" src="https://res.cloudinary.com/nieleche/image/upload/v1674530483/trade_crnhwv.png" alt="Image">
                </figure>
              </div>
              <div class="media-content media-left bg-white">
                <div class="content p16 ">
                  <p class="f20">
                    <small class="f13">Takes about 10 mins</small> 
                    <br>  
                    <strong>Start Trading</strong>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>

        <router-link to="/log-in"  class="button is-ghost bg-purple textdecoration-none color-white f16 my-5">Get Started </router-link>


      </div>
    </div>


       <!-- Ad section -->
    <div class=" bg-purplegradient">
      <div class="container py-5 is-fluid ">
        <div class="columns pt-3">
          <div class="column mt-4">
            <h1 class="menlo color-white f30 text-left ">Do More On <br> RAPIDBEAR</h1>
            <p class="pt-2 lato f13 color-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
          </div>
          <div class="column mt-6">
            <img class=" mt-6" width="185" height="91" src="https://res.cloudinary.com/nieleche/image/upload/v1675389263/Group_54_xxllzc.png" alt="Image">
            <p class="pt-6 lato f13 color-white text-left ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco
            eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
          </div>
          <div class="column">
            <img class="" width="339" height="600" src="https://res.cloudinary.com/nieleche/image/upload/v1675389956/png-transparent-feature-phone-smartphone-handheld-devices-iphone-crypto-coin-gadget-electronics-text_2_qlsmfu.png" alt="Image">
          </div>
        </div> 

        <div class="columns pb-5">
          <div class="column">
            <img class="" width="482" height="500" src="https://res.cloudinary.com/nieleche/image/upload/v1675390688/NFT-alt-mobile_2_zsqxgq.png" alt="Image">
          </div>

          <div class="column mt-4 text-right">
            <h1 class="menlo color-white f30 text-right  is-half">Trade with<br> Confidence</h1>
            <p class="pt-2 lato f13 color-white text-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            <a class="text-right f20 color-white"> <strong>JOIN THE ACADEMY</strong></a>
          </div>
        </div> 
      </div>
    </div>


    <div class=" bg-purple">
      <div class="container px-0 is-fluid ">
        <div class="columns mb-0">
          <div class="column text-left px100">
            <h1 class="menlo color-white f30 text-left ">THE DREAM TEAM</h1>
            <p class="pt-2 lato f13 color-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            <router-link to="/about"  class="button is-ghost bg-black textdecoration-none color-white f16 my-5">Meet The Team </router-link>

          </div>
          <div class="column p-0">
            <img class="fit" src="https://res.cloudinary.com/nieleche/image/upload/v1675394053/Mask_group_bajxj7.png" alt="Image">
          </div>
        </div> 
      </div>
    </div>


       <!-- newsletter section -->
      <div class="bg-black ">
        <div class="container is-max-desktop py-5">
          <h1 class="column is-half is-offset-one-quarter menlo color-white f20 text-center pt-5 px-2">Subscribe To Our NewsLetter</h1>
          <div id="nl">
                <div class="nl-card">


                  <div class="content">
                    <form method="POST" action="#">

                      <input id="email" type="email" name="email" title="email" placeholder="Email" required autofocus>

                      <button type="submit" class="btn btn-primary fsmall">Get Started</button>
                    </form>
                  </div>
                </div>
              </div>
          </div>
      </div>

  </div>

</template>

<script>

export default {
  name: 'HomeView',
  components: {
 
  }
}
</script>
